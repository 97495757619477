.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    padding: 10px;
    margin-top: 50px;
}

.register-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    text-align: left;
}

.register-form label {
    align-self: flex-start;
    margin-bottom: 5px;
    color: #5A5148;
    font-family: 'TruenoLight';
    width: 100%;
    font-size: 14px;
}

.createLabel {
    color: #5A5148;
    font-family: 'Playfair';
    margin-bottom: 30px;
}

.register-form input {
    width: 100%;
    height: 30px;
    padding: 8px;
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #F2F2F0;
}

.register-form input:focus{
    border-color: #5A5148; /* Customize this color */
    outline: none; /* Remove the default outline */
}
.input-error {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
    align-self: flex-start;
}

.message {
    color: green;
    font-size: 12px;
    margin-bottom: 10px;
    align-self: flex-start;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-family: 'TruenoRegular';
    width: 100%;
    justify-content: center;
    position: relative;
}

.checkbox-container input[type="checkbox"] {
    width: 15px; 
    height: 15px;
    margin: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.checkbox-container label {
    margin-left: 20px;
}

.createaccount-button {
    width: 315px;
    height: 45px;
    padding: 10px;
    background-color: #5A5148;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    font-family: 'TruenoRegular';
}

.createaccount-button:hover {
    background-color: #64531b;
}

.createaccount-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
