.promos-page {
    padding: 20px;
}

.promos-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Fixed 3-column layout */
    gap: 24px; /* Larger gap between items */
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 100px;
    padding-bottom: 100px;
    max-width: 1200px; /* Optional: Limit max width for better center alignment */
    margin: 0 auto; /* Center the container */
}

/* Focused Promo Section */
.focused-promo {
    display: flex;
    margin: 0 auto; /* Center the section horizontally */
    margin-bottom: 40px;
    padding-top: 20px;
    gap: 40px;
    justify-content: center; /* Ensure content is aligned to the center */
    max-width: 1200px; /* Optional: limit the max width */
}

/* Focused Promo Image */
.focused-cover {
    width: 300px; /* Adjust width as needed */
    height: 200px;
    object-fit: cover;
    margin-right: 20px;
}

.focused-info {
    flex: 1;
    text-align: left;
    max-width: 500px; /* Limit the width of the info section */
}

.focused-name {
    font-size: 1.8em;
    margin-bottom: 10px;
}

/* Ensure that the image and content are aligned correctly */
.focused-promo img {
    width: 100%;
    max-width: 400px;
    height: auto;
}

.promo-name-link, .focused-name-link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit text color from the parent element */
}

.promo-name-link:hover, .focused-name-link:hover {
    color: inherit; /* Prevent color change on hover */
}

.focused-desc {
    font-size: 1em;
    color: #555;
}

/* Regular Promos Grid */
.promos-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 20px;
    padding: 0 20px; /* Left and right padding */
}

.promo-item {
    padding: 15px;
    text-align: center;
}

.promo-cover {
    width: 100%; /* Full width of the promo item container */
    height: 350px; /* Fixed height for uniform display */
    object-fit: cover; /* Crop the image to fit dimensions */
    cursor: pointer;
}

.promo-name {
    margin-top: 10px;
    font-size: 1.2em;
    cursor: pointer;
}

.show-more-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #5A5148;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.show-more-button:hover {
    background-color: #4A4036;
}

.promo-name, .focused-name {
    margin: 0;
    font-family: 'Playfair' !important;
    color: #5A5148;
    text-align: left;
    width: 58%;
}

html,
body {
    background-color: #F2F2F0;
    margin: 0;
    overflow-x: hidden;
}

.cover-image {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
}

.paragraph-box {
    background-color: white;
    padding: 20px;
    margin: 0;
    text-align: left;
    font-family: 'TruenoLight';
    color: #555;
    line-height: 1.2;
}

.learn-more-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #5A5148;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
}

.learn-more-button:hover {
    background-color: #4A4036;
}

/* Mobile and Tablet Responsiveness */
@media (max-width: 768px) {
    /* Adjust grid for smaller screens */
    .promos-container {
        grid-template-columns: repeat(2, 1fr); /* 2-column layout */
        padding-left: 40px;
        padding-right: 40px;
    }

    /* Focused promo layout */
    .focused-promo {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .focused-promo img {
        width: 100%;
        height: auto;
    }

    .focused-name {
        font-size: 1.5em;
    }

    /* Promo item adjustments for mobile */
    .promo-item {
        padding: 10px;
    }

    .promo-cover {
        height: 250px; /* Smaller image height */
    }

    .promo-name {
        font-size: 1em;
    }

    .show-more-button {
        width: 100%; /* Full width button */
    }
}

@media (max-width: 480px) {
    /* Adjust grid for mobile */
    .promos-container {
        grid-template-columns: 1fr; /* 1-column layout */
        padding-left: 20px;
        padding-right: 20px;
    }

    /* Focused promo layout */
    .focused-promo {
        padding-left: 10px;
        padding-right: 10px;
    }

    .focused-name {
        font-size: 1.3em;
    }

    /* Adjust promo image and text sizes */
    .promo-cover {
        height: 200px; /* Even smaller image height */
    }

    .promo-name {
        font-size: 0.9em;
    }

    .learn-more-button {
        width: 100%; /* Full width for smaller screens */
    }
}
