.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    height: 73vh;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

.loginLabel {
    color: #5a5148;
    font-family: 'Playfair';
    margin-bottom: 20px;
}

.login-form label {
    align-self: flex-start;
    margin-bottom: 5px;
    color: #5a5148;
    font-family: 'TruenoLight';
   
}

.login-form input {
    width: 100%;
    height: 30px;
    padding: 8px;
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f2f2f0;
}

.login-form input:focus{
    border-color: #5A5148; /* Customize this color */
    outline: none; /* Remove the default outline */
}

.password-input-container {
    position: relative;
    width: 100%;
}

.password-input {
    width: calc(100% - 32px);
}

.password-toggle {
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #5a5148;
}

.password-toggle:hover {
    color: #3d352d;
}

.login-button,
.create-account-button {
    width: 315px;
    height: 40px;
    padding: 10px;
    background-color: #5a5148;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-family: 'TruenoRegular';
}

.login-button:hover,
.create-account-button:hover {
    background-color: #3d352d;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.forgot-password {
    align-self: flex-end;
    margin-bottom: 20px;
}

.forgot-password a {
    text-decoration: none;
    color: #5a5148;
    font-family: 'TruenoLight';
}

.or-separator {
    margin: 20px 0;
    font-size: 16px;
    color: #5a5148;
    font-family: 'TruenoLight';
}

.input-error {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 10px;
}

.error-box {
    color: #f61c1c;
    background-color: #ffe5e5; /* Lighter red background */
    border: 2px solid #f1afaf; /* Red border */
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}

.loading-animation-login {
    border: 4px solid #f2f2f0;
    border-top: 4px solid #3d352d;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 3s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
