.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.asw-widget .asw-menu-btn {
  outline: 5px solid #5A5148 !important;
  background: linear-gradient(96deg, #6a6158 0, #5A5148 100%) !important;
  bottom: 20px !important;
  left: 25px !important;
  width: 55px !important;
  height: 55px !important;
}

.asw-menu .asw-menu-header {
  background-color: #5A5148 !important;
}

.asw-menu .asw-menu-header svg {
  fill: #5A5148 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Add responsive design */
@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }
}