.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}


.cart-overlay.open {
  display: block;
}


.cart {
  width: 500px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  background-color: #F2F2F0;
}


.cart.open {
  transform: translateX(0);
  background-color: #F2F2F0;
}

.cart-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Ashfiana';
  margin-bottom: 20px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  right: 20px;
}

.divider {
  height: 1px;
  background-color: #5A5148;
  margin: 0;
  width: calc(100% + 40px);
  position: relative;
  left: -20px;
}

.empty-cart {
  text-align: center;
  margin-top: 20px;
  font-family: 'Creato';
}

.empty-text {
  font-weight: bold;
  font-size: 20px;
}

.empty-cart p {
  margin: 10px 0;
}

.shop-now-btn {
  padding: 10px 20px;
  background-color: #5A5148;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.cart-body {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
  /* Ensure scrollbar doesn't overlap content */
  margin-bottom: 20px;
}

.shop-now-btn:hover {
  background-color: #6c655d;
}

.cart-items {
  display: flex;
  flex-direction: column;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.cart-item img {
  width: 100px;
  height: auto;
  margin-right: 10px;
  cursor:pointer;
}

.cart-item-details {
  flex: 1;
  margin-left: 10px;
  text-align: left;
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-control button {
  border: none;
  background: #5A5148;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}

.quantity-control span {
  margin: 0 10px;
}

.cart-item-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #5A5148;
}

.product-price-cart {
  margin: 0;
  font-weight: lighter;
  font-family: 'TruenoRegular';
  color: #555;
}

.cart-footer {
  padding: 10px 0;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.subtotal {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
}

.subtotal-text {
  font-size: 16px;
  font-weight: 500;
  font-family: 'TruenoRegular';
  color: #5A5148;
}

.subtotal-price {
  font-size: 18px;
  font-weight: 600;
  color: #5A5148;
  font-family: 'TruenoRegular';
  text-align: right;
}

.checkout-btn {
  background-color: #5A5148;
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 15px;
  width: 100%;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-btn:hover {
  background-color: #6e665e;
}

.cart-product-name {
  font-family: 'TruenoRegular';
  color: #5A5148;
  cursor: pointer;
}

.cart-product-desc {
  font-weight: lighter;
  font-family: 'TruenoRegular';
  color: #555;
}

.checkout-btn:disabled{
  background-color: grey;
  cursor: not-allowed;
}