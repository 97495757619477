.forgot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    height: 73vh;
}

.forgot-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

.forgot-form label {
    align-self: flex-start;
    margin-bottom: 5px;
    color: #222831;
    font-family: 'Creato';
    font-weight: 600;
}

.forgotLabel{
    color: #222831;
    font-family: 'Ashfiana';
}
.forgot-form input {
    width: 100%;
    height: 30px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #E5DDD2;
}


.submit-button {
    width: 315px;
    height: 40px;
    padding: 10px;
    background-color: #A78A2C;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.submit-button:hover {
    background-color: #64531b;
}

.email-sent{
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Creato';
}

.back-login {
    align-self: flex-start;
    margin-top: 20px;
}

.back-login a {
    text-decoration: none;
    color: #222831;
    font-family: 'Creato';

}