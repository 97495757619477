html,
body {
    background-color: #F2F2F0;
    margin: 0;
    overflow-x: hidden;
}

.main-container {
    padding: 20px 0;
}

.sign-up-page {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.event-selection {
    text-align: center;
}

.event-selection h2 {
    font-size: 2em;
    color: #5A5148;
    margin-bottom: 20px;
}

.event-buttons {
    display: flex;
    flex-direction: column;
}

.event-buttons button {
    background-color: #5A5148;
    color: #FFF;
    font-size: 16px;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.event-buttons button:hover {
    background-color: #6c635a;
}

.form-container {
    background-color: white;
    padding: 30px;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    font-family: 'Playfair', serif;
    color: #5A5148;
    margin-top: 20px;
    margin-bottom: 20px;
}

.input-group {
    position: relative;
    margin-bottom: 20px;
    display: flex;
}

.input-group input,
.input-group select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-group input:focus,
.input-group select:focus {
    outline: none;
    border-color: #5A5148; /* Input focus border color */
    box-shadow: 0 0 5px rgba(90, 81, 72, 0.5); /* Add shadow effect on focus */
}

.input-group label {
    color: #999;
    font-size: 16px;
    position: absolute;
    pointer-events: none;
    top: 20px;
    left: 10px;
    transition: 0.2s ease all;
}

.input-group input:focus ~ label,
.input-group input:not(:placeholder-shown) ~ label,
.input-group select:focus ~ label,
.input-group select:not(:placeholder-shown) ~ label {
    top: -10px;
    font-size: 12px;
    color: #5A5148;
}

.event-form-button {
    background-color: #5A5148;
    width: 100%; /* Make button full-width */
    height: 50px;
    color: #FFF;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px; /* Add border-radius for uniformity */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Add transition effects */
    margin-top: 20px;
}

.event-form-button:hover {
    background-color: #6c635a;
    transform: translateY(-2px); /* Subtle lift effect */
}

.event-form-button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    opacity: 0.6;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.modal-content {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    margin-bottom: 1rem;
    font-size: 24px;
}

.modal-content p {
    font-size: 16px;
    margin-bottom: 1.5rem;
}

.btn-close-modal {
    padding: 0.5rem 1rem;
    background-color: #5A5148;
    color: white;
    border: none;
    border-radius: 5px; /* Rounded corners for the button */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-close-modal:hover {
    background-color: #6c635a;
}

.back-button {
    position:relative;
    background: none;
    border: none;
    color: #5A5148; /* Change to your preferred color */
    font-size: 35px;
    cursor: pointer;
    display: block;
}

.back-button:hover {
    color: #6c635a;
    text-decoration:underline;
}

