.footer {
  background-color: #5A5148;
  padding: 20px 0;
  color: #F2F2F0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  flex-direction: row;
}

.footer-logo,
.footer-location,
.footer-links,
.footer-subscribe {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.footer-logo {
  flex: 2;
}

.footer-location {
  flex: 3;
}

.footer-links {
  flex: 2;
}

.footer-subscribe {
  flex: 3;
}

.footer-logo img {
  max-width: 100%;
}

.footer-location h4,
.footer-links h4,
.footer-subscribe h4 {
  font-size: 20px;
  margin-bottom: 10px;
  font-family: 'Playfair';
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 8px;
}

.footer-links ul li a {
  color: #F2F2F0;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links ul li a:hover {
  color: white;
}

/* Footer Subscribe Form */
.footer-subscribe form {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.footer-subscribe h4 {
  margin-bottom: 20px;
  text-align: left;
}

.footer-subscribe p {
  font-size: 15px;
  text-align: left;
  font-family: 'TruenoLight';
}

.footer-subscribe input[type="email"] {
  flex: 1;
  padding: 12px;
  border: 1px solid #F2F2F0;
  border-radius: 8px 0px 0px 8px;
  background-color: #5A5148;
  color: #F2F2F0;
}

.footer-subscribe input[type="email"]::placeholder {
  color: #F2F2F0;
  font-family: 'TruenoLight';
}

.footer-subscribe input:focus {
  border-color: none;
  outline: #F2F2F0;
}

.footer-subscribe button {
  padding: 13px 16px;
  border: none;
  background-color: #F2F2F0;
  color: #5A5148;
  cursor: pointer;
  border-radius: 0px 8px 8px 0px;
  transition: background-color 0.3s;
  font-family: 'TruenoLight';
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 0;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo,
  .footer-location,
  .footer-links,
  .footer-subscribe {
    margin: 15px 0;
    flex: none;
    text-align: center;
  }

  .footer-subscribe form {
    width: 100%; /* Ensures the form takes up full width */
  }

  .footer-subscribe input[type="email"] {
    border-radius: 8px 0 0 8px; /* Keeps the rounded corners */
  }

  .footer-subscribe button {
    border-radius: 0 8px 8px 0; /* Keeps the rounded corners */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .footer-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-logo,
  .footer-location,
  .footer-links,
  .footer-subscribe {
    flex: 1 1 45%;
    text-align: center;
  }
}

/* Social links container */
.social-links {
  display: flex;
  justify-content: center;
  gap: 15px; /* Adds space between the icons */
  margin-top: 50px;
}

/* Social link icons */
.social-link-btn {
  width: 40px; /* Adjust the size of icons */
  height: 40px;
  transition: filter 0.3s ease; /* Smooth hover transition */
}

