/* Cancel.css */
.cancel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f8f8; /* Light background color for contrast */
    padding: 20px;
}

.cancel-logo {
    width: 100px; /* Adjust the size as needed */
    height: auto;
    margin-bottom: 20px;
}

.cancel-text {
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
}
