.search-results-page {
  padding: 20px;
  background-color: #f9f9f9;
}

.search-results-page h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.search-results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.search-result-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
}

.search-result-card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.search-result-card-image {
  width: 100%;
  height: auto;
}

.search-result-card-name {
  font-size: 16px;
  padding: 10px;
  color: #333;
}

.search-result-card.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.loading-animation {
  width: 100px;
  height: 100px;
  border: 6px solid #ddd;
  border-radius: 50%;
  border-top-color: #5A5148;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.see-more-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #5A5148;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.see-more-button:hover {
  background-color: #675d54;
}
