@font-face {
    font-family: 'Creato';
    src: url('../fonts/CreatoDisplay-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'TruenoLight';
    src: url('../fonts/TruenoLight.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'TruenoRegular';
    src: url('../fonts/TruenoRegular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Ashfiana';
    src: url('../fonts/Ashfiana\ Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  
  @font-face {
    font-family: 'Playfair';
    src: url('../fonts/Playfair\ Display.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }