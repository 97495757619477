/* Success.css */
.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f0fff4; /* Light green background to indicate success */
    padding: 20px;
}

.success-logo {
    width: 100px; /* Adjust as needed */
    height: auto;
    margin-bottom: 20px;
}

.success-text {
    font-size: 1.5rem;
    color: #2f855a; /* Green text color for success */
    font-weight: bold;
}
