html,
body {
    background-color: #F2F2F0;
    margin: 0;
    overflow-x: hidden;
}

.cover-image {
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

.paragraph-box {
  background-color: white;
  padding: 20px;
  margin: 0;
  text-align: left;
  font-family: 'TruenoLight';
  color: #555;
  line-height: 1.2;
}

.membership-container {
  position: relative; /* Ensures the overlay is positioned relative to this container */
  padding: 20px;
  width: 70%;
  margin: 0 auto;
  gap: 2%;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #ffffff; /* Give it a nice background */
  border-radius: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.membership-container stripe-pricing-table {
  width: 100%;
  margin: 0 auto; /* Center it */
  border-radius: 8px;
  overflow: hidden;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 30px; /* Match container radius */
}

.overlay-content {
  text-align: center;
}

.overlay-button {
  padding: 10px 20px;
  background-color: #5A5148;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.overlay-button:hover {
  background-color: #6e665e;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .membership-container {
    flex-direction: column;
    width: 100%;
    padding: 10px;
    gap: 10px;
  }
}