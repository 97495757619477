:root {
  --bg-color: #F2F2F0;
  --text-color: #000;
  --link-spacing: 10px;
  --transition-duration: 0.3s;
  --transition-ease: ease;
  --navbar-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --announcement-bg: #5A5148;
  --announcement-text-color: #333;
  --navbar-padding: 5px 10px;
  --bottom-navbar-padding: 10px 10px;
  --fixed-top: 70px;
  --hover-bg-color: #f4f1ec;
  --hover-line-color: #5A5148;
  --bottom-navbar-height: 30px;
  --hover-line-thickness: 3px;
}


.navbar-wrapper {
  display: flex;
  flex-direction: column;
}


.navbar,
.fixed-main-navbar {
  height: 50px;
  background-color: var(--bg-color);
  padding: 5px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform var(--transition-duration) var(--transition-ease), opacity var(--transition-duration) var(--transition-ease);
  z-index: 999;
}

.fixed-main-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

}


.navbar-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60%;
}

.logo-container {
  flex: 0 0 auto;
  margin-right: 10px;
  transition: transform var(--transition-duration) var(--transition-ease);
}

.logo-slide-right {
  transform: translateX(20px);
}

.logo-container.logo-slide-left {
  transform: translateX(0);
}

.navbar-logo {
  max-width: 150px;
  height: auto;
  object-fit: cover;
}

.navbar-links {
  display: flex;
  margin-left: 20px;
}

.navbar-links a {
  position: relative;
  color: var(--text-color);
  margin-right: var(--link-spacing);
  text-decoration: none;
  padding: 10px;
  line-height: var(--bottom-navbar-height);
  transition: background-color var(--transition-duration) var(--transition-ease);
}

.navbar-links a:last-child {
  margin-right: 0;
}


.navbar-links a:hover {
  background-color: var(--hover-bg-color);
}

.navbar-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--hover-line-thickness);
  background-color: var(--hover-line-color);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform var(--transition-duration) var(--transition-ease);
}

.navbar-links a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.hamburger-menu {
  font-size: 24px;
  cursor: pointer;
}


.bottom-navbar,
.fixed-bottom-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--bottom-navbar-padding);
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--bg-color);
  box-shadow: var(--navbar-shadow);
  z-index: 999;
  transition: transform var(--transition-duration) var(--transition-ease),
    opacity var(--transition-duration) var(--transition-ease);
  opacity: 1;
  transform: translateY(0);
  height: var(--bottom-navbar-height);
}

.fixed-bottom-navbar {
  position: fixed;
  width: 100%;

  top: 60px;
}

.hide-bottom-navbar {
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
  transition: transform var(--transition-duration) var(--transition-ease),
    opacity var(--transition-duration) var (--transition-ease);
}

.bottom-navbar .navbar-links,
.fixed-bottom-navbar .navbar-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.bottom-navbar .navbar-links a,
.fixed-bottom-navbar .navbar-links a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'TruenoRegular';
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
  transition: background-color var(--transition-duration) var(--transition-ease);
  box-sizing: border-box;
}

.bottom-navbar .navbar-links a:hover,
.fixed-bottom-navbar .navbar-links a:hover {
  background-color: var(--hover-bg-color);
}

.bottom-navbar .navbar-links a::after,
.fixed-bottom-navbar .navbar-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--hover-line-thickness);
  background-color: var(--hover-line-color);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform var(--transition-duration) var(--transition-ease);
}

.bottom-navbar .navbar-links a:hover::after,
.fixed-bottom-navbar .navbar-links a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.show-bottom-navbar {
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
}

.top-bar {
  background-color: #f0f0f0;
  padding: 10px 20px;
  text-align: center;
  font-size: 14px;
  color: #333;
  box-shadow: var(--navbar-shadow);
}


.announcement-bar {
  background-color: var(--announcement-bg);
  top: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: var(--announcement-text-color);
  box-shadow: var(--navbar-shadow);
  z-index: 999;
  color: white;
}

.hide-announcement-bar {
  transform: translateY(-100%);
}


.search-bar {
  position: relative;
  width: 400px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.search-bar input {
  flex: 1;
  border-radius: 25px;
  padding: 8px 40px 8px 20px;
  border: 1px solid;
  outline: none;
  background-color: #F2F2F0;
  width: 100%;
  padding: 8px;
  padding-right: 36px;
}

.search-bar .search-icon {
  position: absolute;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.clear-icon {
  position: absolute;
  right: 36px;
  width: 20px;
  height: auto;
  cursor: pointer;
}

.search-icon {
  position: absolute;
  right: 10px;
  width: 20px;
  height: auto;
  cursor: pointer;
}

.search-bar-container input {
  padding-left: 20px;
}


.search-bar-container.slide-right {
  transform: translateX(20px);
  transition: transform 0.3s ease;
}

.search-bar-container.slide-left {
  transform: translateX(0px);
  transition: transform 0.3s ease;
}

.search-bar-container.slide-left .search-icon {
  transform: translateX(-1px);
  transition: transform 0.3s ease;
}

.search-bar-container.slide-right .search-icon {
  transform: translateX(1px);
  transition: transform 0.3s ease;
}


.user-cart-icons {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.user-cart-icons.left-shift {
  transform: translateX(-60px);
}

.user-cart-icons>* {
  margin-left: 20px;
}

.user-cart-icons>*:first-child {
  margin-left: 0;
}

.user-icon svg,
.cart-icon svg {
  stroke: var (--text-color);
  fill: none;
}

.accountBtn,
.searchToggle {
  border: none;
  padding: 0%;
  margin: 0%;
  background-color: var(--bg-color);
}

.cartBtn {
  border: none;
  padding: 0%;
  margin: 0%;
  background-color: var(--bg-color);
}

.searchToggle {
  display: none;
}

.accountBtn:hover {
  cursor: pointer;
}

.cartBtn:hover {
  cursor: pointer;
}

.searchToggle:hover {
  cursor: pointer;
}

.user-photo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.hamburger-menu-mobile {
  display: none;
}

.logo-container-mobile {
  display: none
}

.mobile-bottom-navbar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F2F2F0;
  z-index: 1000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000;
  transition: transform 0.3s ease-in-out;
}

.mobile-bottom-navbar.open {
  display: none;
  transform: translateY(0);
}


.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.mobile-navbar-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-navbar-links a {
  display: block;
  padding: 10px 0;
  color: #000;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;
}

.mobile-navbar-links a:hover {
  color: #ddd;
}

@media (max-width: 935px){
  .navbar-links a{
    padding: 6px !important;
  }
}

@media (max-width: 900px){
  .search-bar{
    width: 300px;
  }
}

@media (max-width: 800px){
  .search-bar{
    width: 270px;
  }
}

@media (max-width: 768px) {
  .navbar-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .navbar-logo {
    max-width: 100px;
  }

  .search-bar {
    display: none;
  }

  .search-icon {
    display: block;
    cursor: pointer;
  }

  .searchToggle {
    display: block;
    cursor: pointer;
  }

  .search-bar-container.active {
    display: flex;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: var(--bg-color);
    padding: 10px;
    box-shadow: var(--navbar-shadow);
  }

  .mobile-bottom-navbar.open {
    display: flex;
    transform: translateY(0);
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-color);
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    padding: 20px 0;
    box-shadow: var(--navbar-shadow);
  }

  .navbar-links.active {
    display: flex;
  }

  .hamburger-menu {
    display: block;
  }

  .bottom-navbar {
    display: none;
  }

  .search-bar-container.active input {
    display: block;
    width: 100%;
  }

  .search-bar-container.active .search-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .hamburger-menu {
    display: none;
  }

  .hamburger-menu-mobile {
    display: block;
  }

  .logo-container-desktop {
    display: none
  }

  .logo-container-mobile {
    display: block
  }

  .search-overlay-content {
    display: flex !important;
    max-width: 600px;
    /* Default maximum width */
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
}

.no-scroll {
  overflow: hidden;
}

.book-now-btn {
  background-color: #5A5148;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.book-now-btn:hover {
  background-color: #6a6158;
}

@media (max-width: 768px) {
  .book-now-btn {
    padding: 8px 15px;
    font-size: 14px;
    margin-right: 5px;
  }
}

@media (max-width: 480px) {
  .book-now-btn {
    padding: 6px 10px;
    font-size: 12px;
    margin-right: 3px;
    border-radius: 3px;
  }
}

.clear-icon {
  cursor: pointer;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-width: 400px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px;
  box-sizing: border-box;
}


.search-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-results li {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.search-results li:last-child {
  border-bottom: none;
}


.search-results a {
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.search-results a:hover {
  color: #5A5148;
}


.search-results button,
.view-all button {
  background-color: #5A5148;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  display: block;
  width: 100%;
}

.search-results button:hover {
  background-color: #6a6158;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  /* Horizontally center */
  align-items: center;
  /* Vertically center */
  height: 100%;
  /* Adjust based on your needs */
}

.loading-spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left: 3px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.search-results-grid-nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  /* Adjust the gap as needed */
}

.search-result-card-nav {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  text-align: center;
  padding: 8px;
  display: flex;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  flex: 1 0 calc(33.333% - 16px);
  box-sizing: border-box;
}

.view-all {
  margin-top: 16px;
  text-align: center;
}

.search-result-img-nav {
  width: 100%;
  height: auto;
  display: block;
}

.search-result-name-nav {
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
}

.search-result-price-nav {
  margin-top: 4px;
  font-size: 14px;
  color: #333;
}

/* Add these new styles to Navbar.css */

.overlay {
  align-self: center;
  top: 60px;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.fixed-overlay {
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.searchOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.search-overlay-content {
  display: none;
}

.search-overlay-content.fixed {
  position: fixed;
  top: 60px;
  /* Adjust as needed */
}

.search-overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.search-bar-overlay {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 8px 12px;
  background-color: var(--bg-color);
  border-radius: 25px;
  border: none; /* No border */
}


.search-input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.search-input {
  flex: 1;
  padding: 10px;
  border-radius: 25px;
  border: 1px solid #ddd;
}

.search-bar-overlay input {
  width: 100%;
  padding: 8px 50px 8px 12px; /* Space for icons */
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 25px;
  background-color: transparent;
}

.search-bar-overlay .clear-icon-ov {
  right: 40px;
  width: 20px;
  height: 20px;
}

.search-bar-overlay .search-icon-ov {
  right: 10px;
  width: 20px;
  height: 20px;
}

.search-results-list,
.search-results-empty {
  position: absolute;
  top: 100%; /* Below the search bar */
  left: 0;
  width: 100vw; /* Full width of the viewport */
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 0 0 8px 8px; /* Smooth corners at the bottom */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
}



.search-results-list {
  position: absolute;
  /* Position relative to the nearest positioned ancestor (search bar container) */
  top: 100%;
  /* Position it right below the search bar */
  left: 0;
  /* Align to the left of the search bar */
  width: 100%;
  /* Match the width of the search bar */
  background-color: white;
  /* Ensure it's clearly visible */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Add some shadow for a floating effect */
  z-index: 1000;
  /* Ensure it appears above other elements */
}

.search-results-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-results-list li:hover {
  background-color: #f0f0f0;
  /* Highlight the list item on hover */
}

.search-results-empty {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 20px;
  padding-bottom:20px;
}


.search-result-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.search-result-name {
  font-size: 16px;
  margin-right: 10px;
}

.search-result-price {
  font-size: 14px;
  color: #666;
}

.search-button-ov,
.clear-search-ov {
  background: none;
  border: none;
  cursor: pointer;
}

.search-button-ov {
  right: 10px;
}

.clear-search-ov {
  right: 50px;
  /* Adjust this value as needed */
}

@media (max-width: 768px) {
  .search-overlay-content {
    max-width: 100%;
    /* Reduce max width on smaller screens */
    padding: 15px;
    /* Adjust padding for smaller screens */
  }

  .search-bar-overlay {
    padding: 6px 10px;
  }

  .search-bar-overlay input {
    padding: 6px;
    font-size: 14px;
    /* Slightly smaller font size on small screens */
  }

  .search-bar-overlay .clear-icon-ov,
  .search-bar-overlay .search-icon-ov {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 480px) {
  .search-overlay-content {
    max-width: 100%;
    margin: 10px;
    /* More margin for very small screens */
  }

  .search-bar-overlay {
    align-items: stretch;
  }
}

.search-bar-overlay .clear-icon-ov,
.search-bar-overlay .search-icon-ov {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.result-label{
  text-align: left;
  padding-left: 10px;
}
