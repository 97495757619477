.modal-overlay-promo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content-promo {
    background-color: white;
    padding: 20px;
    max-width: 600px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-close-promo {
    background: none;
    border: none;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #5A5148;
}

.modal-content-promo h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Playfair' !important;
    color: #5A5148 !important;
}

.modal-content-promo form {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.promo-form-section-promo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.form-group-promo {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    display: grid;
}

.modal-content-promo input, .modal-content-promo select, .textarea-promo textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
}

.form-group-promo label {
    top: 8px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    color: #999;
    transition: 0.2s;
    cursor: text;
}

.floating-label-promo{
    position: absolute;
}

.loading-spinner-promo {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }

.form-group-promo input:focus + label,
.form-group-promo input:not(:placeholder-shown) + label {
    top: -8px;
    left: 5px;
    font-size: 12px;
    color: #5A5148;
}

.textarea-promo textarea {
    resize: vertical;
    min-height: 150px;
    width: 300px;
}

.modal-buttons-promo button{
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    background-color: #5A5148;
    color: white;
    border: none;
}

.submit-btn-promo:hover,
.next-btn-promo:hover,
.prev-btn-promo:hover {
    opacity: 0.8;
}

.progress-bar-promo {
    background-color: #f0f0f0;
    border-radius: 8px;
    height: 10px;
    width: 70%;
    margin-bottom: 20px;
}

.progress-promo {
    background-color: #5A5148;
    height: 100%;
    width: 0;
    border-radius: 8px;
}

.modal-buttons-promo {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 20px;
}

.modal-buttons-promo .prev-btn-promo {
    margin-right: auto;
}

.next-btn-promo {
    background-color: #5A5148; /* Normal button color */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.next-btn-promo:hover {
    background-color: #4A4036; /* Darker shade for hover effect */
}

.next-btn-promo:disabled {
    background-color: #ccc; /* Lighter shade for disabled state */
    color: #666;
    cursor: not-allowed;
    opacity: 0.6; /* Makes the button appear faded */
}

.date-field-promo, .time-field-promo{
    margin-left: 10px;
}

.date-time-label-promo{
    margin-right: 10px;
}

.summary-section-promo {
    padding: 20px;
    border-radius: 8px;
    background-color: #fafafa;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    max-width: 500px;
}

.summary-section-promo h2 {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}

.scrollable-notes-promo {
    max-height: 100px; /* Set a maximum height for the notes */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
    padding-right: 5px; /* Optional: add padding for scroll visibility */
}


.summary-details-promo {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.summary-item-promo {
    display: flex;
    flex-direction: column; /* Stack label and value vertically */
    font-size: 1rem;
    line-height: 1.5;
}

.summary-label-promo {
    font-weight: bold;
    color: #5A5148;
    margin-bottom: 4px; /* Add spacing between label and value */
}

.summary-value-promo {
    font-style: italic;
    color: #555;
    word-wrap: break-word;
}


