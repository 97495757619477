.promo-detail-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.promo-detail-content {
    width: 70%;
    display: flex;
    margin: 0 auto;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
}

.item-image-container-p {
    width: 70%;
    height: 450px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1em;
}

.promo-detail-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.promo-detail-info {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.item-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.promo-name {
    font-size: 30px;
    margin: 0;
    font-family: 'Playfair' !important;
    color: #5A5148;
    text-align: left;
    width: 58%;
}

.item-description {
    font-size: 14px;
    color: #666;
    margin: 0;
    text-align: left;
    line-height: 2;
    font-family: 'TruenoLight' !important;
}

.promo-desc,
.promo-info {
    margin-bottom: 10px;
}

.book-now-button {
    padding: 15px 30px;
    font-size: 1.2em;
    color: #fff;
    background-color: #5A5148;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 200px;
    height: 60px;
}

.book-now-button:hover {
    background-color: #4A4036;
}

.promo-modal {
   
}